export function formatStNrForELSTER(stnr, taxOffice) {
  if (taxOffice && stnr) {
    //In HESSEN, they sometimes, but not always write down the "0" in their paper mails
    if (taxOffice === "26" && stnr.length === 11 && stnr.charAt(0) === "0") {
      const stnrCleaned = stnr.substring(1);
      return standardizedElsterFormat(stnrCleaned, taxOffice);
    }
    //In Bavaria, they sometimes, but not always don't write down the "finanzamtsnummer" in their paper mails
    if (taxOffice === "9" && stnr.length === 8) {
      const finanzamtsnummer = "198";
      const stnrCleaned = finanzamtsnummer.concat(stnr);
      return standardizedElsterFormat(stnrCleaned, taxOffice);
    }
    return standardizedElsterFormat(stnr, taxOffice);
  }
}

function standardizedElsterFormat(stnr, taxOffice) {
  const strnWithTaxOffice = taxOffice.concat(stnr);
  const formattedElsterStrn = [
    strnWithTaxOffice.slice(0, 4),
    "0",
    strnWithTaxOffice.slice(4),
  ].join("");
  return formattedElsterStrn;
}
