import Button from "components/Button";
import { useUserData } from "data-layer/userData";
import { useUserInteraction } from "data-layer/userInteraction";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ErrorMessage({ code, success }) {
  const [errorsVisible, setErrorsVisible] = useState(false);
  const { userData } = useUserData();
  const { t } = useTranslation();
  const { userInteraction } = useUserInteraction();
  return (
    <>
      <div className="body--small">
        {code && !success && (
          <>
            <span className="error-code">
              Code: {code}
              {code === 103 && userData.personalFields.optin === true
                ? t("error-code-103-description")
                : code === 103 && userData.personalFields.optin.optin !== true
                ? t("error-code-103-description_no-optin")
                : ""}
            </span>
          </>
        )}

        {userInteraction?.data?.data?.messages?.errors?.map((err, idx, arr) => {
          return (
            <span key={err?.message || idx} className="error">
              <p>
                <strong>Error:</strong> {err?.message.replace(/.+:\s/, "")}
              </p>
              <p>
                <strong>Field:</strong> {err?.context?.field}
              </p>
            </span>
          );
        })}
      </div>
      <div className="qa-errors">
        {code && !success ? (
          <div>
            <div className="flex--between">
              <Button
                inline
                fluid
                func={() => setErrorsVisible(!errorsVisible)}
                text={
                  errorsVisible ? t("hide_full_error") : t("show_full_error")
                }
              />
              <Button
                fluid
                title={t("copy_to_clipboard")}
                className={"submission-error__copy-icon"}
                func={() =>
                  navigator.clipboard.writeText(
                    JSON.stringify(userInteraction?.data || {}, null, 2)
                  )
                }
              >
                📋
              </Button>
            </div>
            {errorsVisible && (
              <>
                <pre className="submission-error">
                  {JSON.stringify(userInteraction?.data || {}, null, 2)}
                </pre>
              </>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}
