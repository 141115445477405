import { sanitizeNumbers } from "res/lib";

export function getNextVATDeadline(userData, lang) {
  const formatter = new Intl.DateTimeFormat(lang, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const { d, m, y } = userData.taxInfoFields?.startdate;
  let nextVATDeadline = new Date(y, parseInt(m, 10) - 1, d);
  if (nextVATDeadline < new Date()) nextVATDeadline = new Date();

  if (nextVATDeadline.getDate() > 10) {
    nextVATDeadline.setMonth(nextVATDeadline.getMonth() + 1);
  }
  nextVATDeadline.setDate(10);

  return {
    vatDeadline: formatter.format(nextVATDeadline),
    showChargeVat: !!userData.taxInfoFields.chargeVAT,
  };
}

export const getNextQuarter = (date) => {
  const currentQuarter = Math.floor(date.getMonth() / 3 + 1);
  let nextQuarter = 0;
  if (currentQuarter < 4) {
    nextQuarter = currentQuarter + 1;
  } else {
    nextQuarter = 1;
  }
  nextQuarter === 1
    ? date.setMonth(0) && date.setFullYear(date.getFullYear() + 1)
    : nextQuarter === 2
    ? date.setMonth(3)
    : nextQuarter === 3
    ? date.setMonth(6)
    : date.setMonth(9);
  return nextQuarter;
};

const dateAsObject = (date) => {
  const formatter = new Intl.DateTimeFormat("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const dateWithDots = formatter.format(date);
  const dateToArray = dateWithDots.split(".");
  const dateAsObject = {
    d: dateToArray[0],
    m: dateToArray[1],
    y: dateToArray[2],
  };
  return dateAsObject;
};

const professionsExcludedfromVAT = [
  "Arzt/Ärztin",
  "Diätassistent*in",
  "Hebamme",
  "Gesundheits und Krankenpfleger*in",
  "Altenpfleger*in",
  "Medizinische*r Fachangestellte*r",
  "Zahnmedizinische*r Fachangestellte*r",
  "Bademeister*in (medizinische/-r)",
  "Pharmazeutisch technische Assistenz",
  "Rettungsassistent*in",
];

export const getFirstVATDeadline = (userData) => {
  const { estimatedSalesTaxAmount, chargeVAT } = userData.taxInfoFields;
  const profession = userData.businessFields.professionPicker?.value;
  const isProfessionExemptforVAT =
    professionsExcludedfromVAT.includes(profession);

  let zahllast = sanitizeNumbers(estimatedSalesTaxAmount);
  let firstVATDeadline = new Date();
  let vatPayingRhythm = "";

  if (chargeVAT === "no" || isProfessionExemptforVAT) {
    vatPayingRhythm = "noSubjectToVAT";
    zahllast = 0;
    firstVATDeadline = "";
  } else if (zahllast < 7500) {
    vatPayingRhythm = "quarterlyVAT";
    getNextQuarter(firstVATDeadline);
    firstVATDeadline.setDate(10);
    firstVATDeadline = dateAsObject(firstVATDeadline);
  } else {
    vatPayingRhythm = "monthlyVAT";
    firstVATDeadline.setMonth(firstVATDeadline.getMonth() + 1);
    firstVATDeadline.setDate(10);
    firstVATDeadline = dateAsObject(firstVATDeadline);
  }
  return {
    vatPayingRhythm,
    firstVATDeadline,
  };
};

export const getFirstZMDeadline = (userData) => {
  const { chargeVAT, askVATnumber, revenue_firstYear } = userData.taxInfoFields;
  const revenue_firstYear_sanitized = sanitizeNumbers(revenue_firstYear);
  const estimatedQuarterlyRevenue =
    (revenue_firstYear_sanitized / (11 - new Date().getMonth())) * 3;
  let ZMPayingRhythm = "";
  let firstZMDeadline = "";

  if (chargeVAT === "yes" && askVATnumber === "yes") {
    const quarterlyThreshold = Math.round(estimatedQuarterlyRevenue);
    firstZMDeadline = new Date();

    if (quarterlyThreshold > 50000) {
      ZMPayingRhythm = "monthlyZM";
      firstZMDeadline.setMonth(firstZMDeadline.getMonth() + 1);
      firstZMDeadline.setDate(25);
      firstZMDeadline = dateAsObject(firstZMDeadline);
    } else {
      ZMPayingRhythm = "quarterlyZM";
      getNextQuarter(firstZMDeadline);
      firstZMDeadline.setDate(25);
      firstZMDeadline = dateAsObject(firstZMDeadline);
    }
  }

  return {
    firstZMDeadline,
    ZMPayingRhythm,
  };
};
