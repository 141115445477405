import produce from "immer";
import qs from "qs";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { isDev } from "settings/config";

///Change this value for the group you want to test in GOOGLE ADS. This is NOT the controllgroup. It has to be identical with the GoogleAds ParameterValue
const defaultGroupName = "free";

function handleVersionQueryString(searchQuery, prop) {
  const urlParamsOnly = searchQuery.replace(/^\?/, "");
  if (!urlParamsOnly) {
    return "control";
  }
  const result = qs.parse(urlParamsOnly)[prop] || defaultGroupName;
  return result;
}

const UserTestingContext = createContext();

const useUserTesting = () => {
  return useContext(UserTestingContext);
};

const UserTestingProvider = ({ children }) => {
  const { search } = useLocation();
  const initialTestingData = {
    successPage:
      Math.random() > 0.5 ? "140422_testGroup" : "140422_controlGroup",
  };

  const localStorageUserTestingData = JSON.parse(
    localStorage.getItem("userTesting")
  );
  const [userTesting, setUserTestingHook] = useState(
    localStorageUserTestingData
      ? localStorageUserTestingData
      : initialTestingData
  );
  useEffect(() => {
    if (localStorageUserTestingData) {
      setUserTestingHook(localStorageUserTestingData);
    }
  }, []);

  /**
   *
   * @param {*} newUserTesting
   * @param {boolean} save
   */
  const setUserTesting = (newUserTesting, save = true) => {
    isDev && console.log(`updating userTesting`);
    const newData = produce(userTesting, (draft) => {
      Object.assign(draft, newUserTesting);
    });

    if (save) {
      localStorage.setItem("userTesting", JSON.stringify(newData));
    }

    setUserTestingHook(newData);
  };
  return (
    <UserTestingContext.Provider value={{ userTesting, setUserTesting }}>
      {children}
    </UserTestingContext.Provider>
  );
};

export { UserTestingContext, useUserTesting, UserTestingProvider };
