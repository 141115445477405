import ManageTaxesCTA from "components/CTA/ManageTaxesCTA";
import { ExternalLink } from "components/Link";
import Table, { sampleTableData } from "components/Table";
import { useUserData } from "data-layer/userData";
import { useUserInteraction } from "data-layer/userInteraction";
import { useUserTesting } from "data-layer/userTesting";
import i18n from "i18n";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { sendAmplitudeData } from "res/amplitude";
import { isDev, webAppLink } from "settings/config";
import Fieldset from "../Fieldset";

const ManageTaxes = () => {
  const { userInteraction } = useUserInteraction();
  const { userData } = useUserData();
  const { t } = useTranslation();
  const [fakeSuccess, setFakeSuccess] = useState(false);
  const { userTesting } = useUserTesting();
  const { successPage } = userTesting;
  function handleFakeBtn(e) {
    e.preventDefault();
    setFakeSuccess(!fakeSuccess);
  }

  return (
    <>
      <form id={"manageTaxes"}>
        <div className="form">
          {isDev ? <button onClick={handleFakeBtn}>Fake Succcess</button> : ""}

          <Fieldset section>
            <div className="screen-header">
              {userInteraction.send || fakeSuccess ? (
                <>
                  {successPage === "140422_testGroup" ? (
                    <h2
                      className="screen-title"
                      dangerouslySetInnerHTML={{
                        __html: t("tab_manageTaxes_heading_testGroup", {
                          interpolation: false,
                          name: userData.personalFields.firstname,
                        }),
                      }}
                    />
                  ) : (
                    <h2
                      className="screen-title"
                      dangerouslySetInnerHTML={{
                        __html: t("tab_manageTaxes_heading", {
                          interpolation: false,
                          name: userData.personalFields.firstname,
                        }),
                      }}
                    />
                  )}
                  {successPage === "140422_testGroup" ? (
                    <p className="screen-subtitle body--medium">
                      {t("tab_manageTaxes_heading_2_testGroup")}
                    </p>
                  ) : (
                    <h2
                      className="screen-title"
                      dangerouslySetInnerHTML={{
                        __html: t("tab_manageTaxes_heading_2", {
                          interpolation: false,
                        }),
                      }}
                    />
                  )}
                </>
              ) : (
                <h2
                  className="screen-title"
                  dangerouslySetInnerHTML={{
                    __html: t("tab_manageTaxes_heading_empty", {
                      interpolation: false,
                    }),
                  }}
                />
              )}

              {userInteraction.send || fakeSuccess ? (
                successPage === "140422_testGroup" ? (
                  <>
                    <h3 className="screen-subtitle body--medium">
                      {t("tab_manageTaxes_questionHeading1_testGroup")}
                    </h3>
                    <p className="screen-subtitle body--small">
                      {t("tab_manageTaxes_questionAnswer1_testGroup")}
                    </p>
                    <br />
                    <h3 className="screen-subtitle body--medium">
                      {t("tab_manageTaxes_questionHeading2_testGroup")}
                    </h3>
                    <p className="screen-subtitle body--small">
                      {t("tab_manageTaxes_questionAnswer2_testGroup")}
                    </p>
                    <br />
                    <br />
                  </>
                ) : (
                  <p className="screen-subtitle screen-subtitle--manageTaxes body--small">
                    {t("tab_manageTaxes_subtitle")}
                  </p>
                )
              ) : (
                <ExternalLink
                  text={t("test_app_for_free")}
                  func={() =>
                    sendAmplitudeData("RASO_CLICKED_DOWNLOADAPP", {
                      experiment: successPage,
                    })
                  }
                  href={webAppLink}
                  target="_blank"
                />
              )}
            </div>
            {userInteraction.send || fakeSuccess ? (
              successPage === "140422_testGroup" ? (
                <>
                  <div className="form__review--actions">
                    <div className="tab-helper--warning">
                      <h2 className="tab-helper--warning_header">
                        {t("tab_manageTaxes_warning_header")}
                      </h2>
                      <p className="tab-helper--warning_body">
                        {t("tab_manageTaxes_warning_body")}
                      </p>
                      <div className="tab-helper--warning_btnContainer"></div>
                    </div>
                  </div>
                  <div className="form__review--warning_CTA">
                    <ExternalLink
                      text={t("test_app_for_free")}
                      func={() =>
                        sendAmplitudeData("RASO_CLICKED_DOWNLOADAPP", {
                          experiment: successPage,
                          button: "cta_anxiety_message",
                        })
                      }
                      href={
                        userInteraction.downloadAppLink_desktop ||
                        `http://onboarding.accountable.de/${
                          i18n.language === "de" ? "" : "en"
                        }`
                      }
                      target="_blank"
                    />
                  </div>
                </>
              ) : (
                <Table tableData={sampleTableData} />
              )
            ) : (
              ""
            )}
            <ManageTaxesCTA />
          </Fieldset>
        </div>
      </form>
    </>
  );
};
export default ManageTaxes;
