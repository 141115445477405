import { gtagId } from "../settings/config";

export const initGTM = () => {
  ((w, d, s, l, i) => {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", gtagId);
};

export const gtagEvent = (event, payload = {}) => {
  if (window.dataLayer) {
    return window?.dataLayer?.push({ event: event, payload: payload });
  } else {
    setTimeout(() => {
      return window?.dataLayer?.push({ event: event, payload: payload });
    }, 1000);
  }
};
