import clsx from "clsx";
import Button from "components/Button";
import { ExternalLink } from "components/Link";
import Terms from "components/Terms";
import React from "react";

import { useUserInteraction } from "data-layer/userInteraction";
import "./hero.css";

const RasoHero = ({ terms, h1, h3, ctaText, ctaLink, ctaFunc }) => {
  const { userInteraction } = useUserInteraction();

  return (
    <>
      <div className="top-section-raso-container" id="rasoHead">
        <h1 className="top-section__title-l top-section__title-l--raso">
          {h1}
        </h1>
        {h3 && (
          <h3 className="top-section__title-m top-section__title-m--raso">
            {h3}
          </h3>
        )}
        {terms && <Terms vertical offerPromises={terms} />}
        {ctaText && (
          <>
            {userInteraction.startedFilling ? null : ctaLink ? (
              <ExternalLink
                href={ctaLink}
                text={ctaText}
                func={ctaFunc}
                className={clsx(
                  "body--big-bold",
                  "form_submit_btn--raso-header"
                )}
              />
            ) : (
              <Button
                text={ctaText}
                func={ctaFunc}
                className={clsx(
                  "body--big-bold",
                  "form_submit_btn--raso-header"
                )}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RasoHero;
