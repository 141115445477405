import { ExternalLink } from "components/Link";
import { useTranslation } from "react-i18next";
import React from "react";

const PreviewLink = ({ previewLink, linkRef }) => {
  const { t } = useTranslation();
  return (
    <div className="tab-helper">
      <p className="tab-helper__heading">
        {t("review_proof_read", {
          interpolation: { escapeValue: false },
        })}
      </p>
      <ExternalLink
        secondary
        ref={linkRef}
        className="body--big-bold"
        href={previewLink}
        target={"_blank"}
        rel="noopener"
        text={t("review_button_view_pdf")}
        autoFocus
      />
    </div>
  );
};

export default PreviewLink;
