export const isDev = /localhost/gi.test(window.location.href)
export const isStaging = /vercel|hazetheai/gi.test(window.location.href)
export const corsProxy = "https://cors-anywhere.herokuapp.com/"
export const accountableDomain = "https://accountable.de"

export const appHost = isDev
  ? "http://localhost:3000"
  : isStaging
  ? "https://raso.vercel.app"
  : "https://registrierung.accountable.de"

export const wpHost = accountableDomain
export const accountableApp = "https://app.accountable.eu"
export const intercomId = "c9wnmh12" //c9wnmh12 for prod
export const amplitudeKey = "038f230707149d3a05d6fa38d750c56a"
export const fbPixelId = "2207534789479329" //its prod
export const gtagId = "GTM-5SW8SVC"
export const country = "de"
export const dlAppLink = "https://accountable.app.link/FTshY7oGF3?"
export const webAppLink = "https://onboarding.accountable.de/"
