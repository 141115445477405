import Button from "components/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { sendAmplitudeData } from "res/amplitude";
import { gtagEvent } from "res/gtag";

const FormSubmit = ({ comingStep, gtagButton, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="form_submit">
      <Button
        type="submit"
        text={`${t("form_continue")}: ${t(comingStep.tabLabel)}`}
        func={() => {
          gtagEvent('raso_clicked_button')
          sendAmplitudeData("RASO_CLICKED_BUTTON", {
            button: gtagButton,
          });
          if (typeof onClick === "function") {
            onClick();
          }
        }}
        onClick={onClick}
      />
    </div>
  );
};

export default FormSubmit;
