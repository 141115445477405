import React from "react";
import { useTranslation } from "react-i18next";
import { useUserData } from "data-layer/userData";
import { useUserInteraction } from "data-layer/userInteraction";
const SuccessHero = () => {
  const {
    userData: { personalFields },
  } = useUserData();
  const { t } = useTranslation();
  const { userInteraction } = useUserInteraction();
  // const taxOffice = reviewFields?.taxOffice
  //   ? tax_offices.find((el) => el.value === reviewFields.taxOffice)?.name
  //   : "";

  // const startdate = `${taxInfoFields.startdate.d}.${taxInfoFields.startdate.m}.${taxInfoFields.startdate.y}`;

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (userTesting.successPage === "a") {
  //     sendAmplitudeData("RASO_TAB", { tab: "BookACall" });
  //     sendAmplitudeData("RASO_TAB-ITER-1", { tab: "BookACall" });
  //   }
  // }, []);
  return (
    <div
      className="top-section-estirev-container top-section-raso-congrats-container"
      id="rasoHeadCongrats"
    >
      <div className="raso-congrats">
        <h1
          className="section-h2 section-h2--raso-h2"
          dangerouslySetInnerHTML={{
            __html: t(`success_hero_title`, {
              interpolation: { escapeValue: false },
            }),
          }}
        />
        <div className="raso-congrats_address_box raso-a-section">
          <h2 className="raso-congrats_p">{t(`success_hero_subtitle`)}</h2>
          <p className="raso-congrats_p">
            <span style={{ fontStyle: "initial" }}>🤓</span>
            {t(`success_hero_review`)}
          </p>
          <p className="raso-congrats_p">
            ✉️ {t(`success_hero_response`)} {personalFields.email}
            <span className="raso-congrats_p--start-date" />
          </p>
          <p className="raso-congrats_p">✏️{t(`success_hero_adjustments`)}</p>
          <p className="raso-congrats_p">
            🏢 {t(`success_hero_finanzamt_office`)}
          </p>
        </div>
        <span className="raso-congrats_ticket">
          <span className="ticket-text">{t(`success_hero_ref_number`)} </span>{" "}
          <span id="tickedId">
            <strong>
              {userInteraction.ticketId ||
                userInteraction?.data?.data?.ticket ||
                ""}
            </strong>
          </span>
        </span>
        <p className="raso-congrats_p">{t(`success_hero_support`)}</p>
      </div>
    </div>
  );
};

export default SuccessHero;
