import clsx from "clsx";
import React from "react";

import { useLocation } from "react-router";

import logo from "res/images/logo.svg";
import rasoBg from "res/images/top-section.png";

import { useUserTesting } from "data-layer/userTesting";
import "./header.css";
import Hero from "./Hero";
import LanguagePicker from "./LanguagePicker";
import Nav from "./Nav";

const Header = () => {
  const { pathname } = useLocation();
  const isSuccess = /success|erfolg/gi.test(pathname);

  return (
    <>
      <div className={clsx("top-section")}>
        <div
          className={clsx(
            "top-section-gradient",
            isSuccess && "top-section-gradient--raso-success"
          )}
        />
        <header className="top-section__header">
          <a className="top-section__header__link" href="/">
            <img
              src={logo}
              className="top-section__header__logo"
              alt="Accountable"
            />
          </a>
          <Nav />
          <LanguagePicker />
        </header>
        <Hero isSuccessPage={isSuccess} />
      </div>
    </>
  );
};

export default Header;
