export const initialUserData = {
  // Personal
  personalFields: {
    firstname: "",
    name: "",
    email: "",
    phone: "+49 ",
    gender: "choose",
    birthdate: { d: "", m: "", y: "" },
    address_street: "",
    address_number: "",
    address_city: "",
    address_postcode: "",
    religion: "choose",
    maritalstatus: "001", // edit here
    maritalstatusdate: { d: "", m: "", y: "" },
    partner_firstname: "",
    partner_name: "",
    partner_gender: "choose",
    partner_birthdate: { d: "", m: "", y: "" },
    partner_religion: "choose",
    moved: "no",
    past_address_street: "",
    past_address_number: "",
    past_address_city: "",
    past_address_postcode: "",
    movingdate: { d: "", m: "", y: "" },
    optin: true,
  },
  // Business
  businessFields: {
    profession: "",
    professionPicker: {},
    officeaddress: "no",
    office_address_street: "",
    office_address_number: "",
    office_address_city: "",
    office_address_postcode: "",
    previousbusiness: "no",
  },
  // Tax Info
  taxInfoFields: {
    steueridentifkationsnummer: "",
    steuernummer: "no",
    steuernummer_value: "",
    steuernummer_state: "choose",
    singleentry: "yes",
    startdate: {
      d: new Date().getDate().toString().padStart(2, "0"),
      m: (new Date().getMonth() + 1).toString().padStart(2, "0"),
      y: new Date().getFullYear(),
    },
    revenue_firstYear: "",
    revenue_secondYear: "",
    chargeVAT: "",
    firstVATDeadline_at: "",
    vatPayingRhythm: "",
    askVATnumber: "",
    firstZMDeadline_at: "",
    ZMPayingRhythm: "",
  },
  // Tax Estimate
  taxEstimateFields: {
    profitFreiberufler: "no",
    profitFreiberuflerFirstYear: "",
    profitFreiberuflerSecondYear: "",
    profitGewerbetreibender: "no",
    profitGewerbetreibenderFirstYear: "",
    profitGewerbetreibenderSecondYear: "",
    profitNichtselbstandiger: "no",
    profitNichtselbstandigerFirstYear: "",
    profitNichtselbstandigerSecondYear: "",
    profitKapitalvermogen: "no",
    profitKapitalvermogenFirstYear: "",
    profitKapitalvermogenSecondYear: "",
    profitVermietung: "no",
    profitVermietungFirstYear: "",
    profitVermietungSecondYear: "",
    profitSonstigen: "no",
    profitSonstigenFirstYear: "",
    profitSonstigenSecondYear: "",
    profitAgriculture: "no",
    taxPrepayment: "no",
    sonderausgabenFirstYear: "",
    sonderausgabenSecondYear: "",
    steuerabzugsbetrageFirstYear: "",
    steuerabzugsbetrageSecondYear: "",
  },
  // Bank Account
  bankAccountFields: {
    showBusinessBankAccount: "no",
    businessIban: "",
    businessBankAccountOwner: "",
    businessBankAccountOwnerCategory: "1",
    showPersonalBankAccount: "no",
    privateIban: "",
    privateBankAccountOwner: "",
    personalBankAccountOwnerCategory: "1",
  },
  // Review
  reviewFields: {
    taxOffice: "choose",
    finanzamtLetters: [],
    usecase: "",
  },
};
