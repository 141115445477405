import React from "react";
import { useController } from "react-hook-form";
import Select from "react-select";

const colourStyles = {
  control: (base) => ({
    ...base,
    "&:hover": { border: " 1px solid var(--color-serious_purple)" },
    border: "1px solid rgba(102, 75, 129, 0.2)",
    boxShadow: "var(--color-serious_purple)",
    padding: "0.25rem",
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused
        ? "var(--color-primary_transparent-3)"
        : isSelected
        ? "var(--color-serious_purple)"
        : "rgba(169, 153, 183, 0.05)",
    };
  },
};

export const AutoCompleteDropdown = ({
  options,
  name,
  control,
  onBlur,
  placeholder,
  components,
}) => {
  const {
    field: { value, onChange },
  } = useController({ control, name, defaultValue: "" });
  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      placeholder={placeholder}
      isClearable
      components={components}
      styles={colourStyles}
    />
  );
};
