import React from "react";
import "./badges.css";
import BafinImg from "./bafin.png";
import ElsterImg from "./elster.png";
import HostedInGermanyImg from "./hostedingermany.png";
import TuevImg from "./Tuev.png";

const Badges = () => {
  return (
    <div className="badges">
      <img src={ElsterImg} alt="ELSTER" />
      <img src={BafinImg} alt="Bafin" />
      <img src={TuevImg} alt="Tüv" />
      <img src={HostedInGermanyImg} alt="Hosted in Germany" />
    </div>
  );
};
export default Badges;
