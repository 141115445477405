import ManageTaxesCTA from "components/CTA/ManageTaxesCTA";
import FAQ from "components/FAQ";
import Form from "components/Form/";
import Table, { sampleTableData } from "components/Table";
import Toast from "components/Toast";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUserInteraction } from "data-layer/userInteraction";

const Test = () => {
  return (
    <div className="content">
      <Table tableData={sampleTableData} />
      <ManageTaxesCTA />
    </div>
  );
};

export default Test;
